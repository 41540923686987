import React from 'react';
import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './navigation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle, faComments, faBell } from '@fortawesome/free-solid-svg-icons';
import circleButton from '../../assets/circleButton34.svg';
import Notifications from '../notifications/notifications';
import GreenDot from "./greendot";

const tabs = [{
  route: "/home",
  icon: faHome,
  label: "Home"
},{
  route: "/search",
  icon: faSearch,
  label: "Search"
},{
  route: "/circles",
  icon: faUserCircle,
  circle: circleButton,
  label: "Circles"
},{
  route: "/posts",
  icon: faComments,
  label: "Posts"
},{
    route: "/notifications",
    icon: faBell,
    label: "Notifications"
  }]

const Navigation = ({notifications}: any) => {

return (
      <div>
      <nav className="navbar navbar-expand-md navbar-light d-none d-lg-block sticky-top" role="navigation">
        <div className="container-fluid">
            <a className="navbar-brand" href="/home"></a>
            <Nav className="ml-auto" onClick={()=>{ window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });}}>
            <NavItem>
                <NavLink to="/home" className="nav-link">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/search" className="nav-link">
                  Search
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/circles" className="nav-link">
                  Circles
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/posts" className="nav-link">
                  Posts
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/notifications" className="nav-link">
                  Notifications
                  <GreenDot visible={notifications.some((v:any)=>!v.read)} />
                </NavLink>
              </NavItem>        
            </Nav>
        </div>
      </nav>
     <nav className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
     <Nav className="w-100">
       <div className=" d-flex flex-row justify-content-around w-100">
         {
           tabs.map((tab, index) =>(
             <NavItem key={`tab-${index}`}>
               <NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                 <div className="row d-flex flex-column justify-content-center align-items-center">
                   {!tab.circle && <FontAwesomeIcon size="lg" icon={tab.icon}/>}
                   {tab.circle && <img height={'22px'} src={tab.circle} />}
                   <div className="bottom-tab-label">{tab.label}</div>
                   {tab.label==="Notifications" && (<GreenDot visible={notifications.some((v:any)=>!v.read)}/>)}
                 </div>
               </NavLink>
             </NavItem>
           ))
         }
       </div>
     </Nav>
   </nav>
   </div>
  )
};

export default Navigation;