import React, {useEffect} from 'react';
import "./messages.scss";

const Messages = ({messages, contact}:any) => {
    let rendMsgs = messages.filter((v:any)=>(v.from===contact.id||v.to===contact.id));
    useEffect(()=>{
        let div = document.getElementById('msgdiv');
        div?.scrollTo(0, div.getBoundingClientRect().top);
    },[]);
    return (
        <div id={"msgdiv"} className={"messages"}>
            {
                rendMsgs.map((msg:any)=>(<div className={msg.to===contact.id?'message outbound':'message inbound'}><div className={(msg.to===contact.id?'outbound':('inbound'+(msg.read?'':' unread')))}><p>{msg.text}</p></div></div>))
            }
        </div>
    )
}
export default Messages;