import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import rando from '../../assets/rando.png';
import './contacts.scss';
type contactType = {
    id: string,
    picture: string,
    name: string
}
type messageType = {
    from: string,
    to: string,
    text: string,
    read: boolean,
}
const Contacts = ({user, contacts, requests, messages, dispatchDM, acceptChatRequest, rejectChatRequest}: any) => {
    const handleClick = (v:contactType) => ()=> {
        dispatchDM({type: "OPEN MESSAGES", payload: {contact: {...v}}});
    }
    const handleClickR = (r:any) => ()=> {
        dispatchDM({type: "VIEW REQUEST", payload: {request: {...r}}});
    }
    let contMap: { [key: string]: number } = {};
    messages.forEach((v:messageType,i:number)=>{
        if(v.from!==user.id&&!v.read){
            contMap[v.from] = (contMap[v.from]||0)+1;
        }
    });
    return (
        <div className={"contacts"}>
            {
                contacts.map((v:contactType)=>(
                    <div key={v.id} className={"contact"} onClick={handleClick(v)}><img src={v.picture} height="50px" /><div className={"contact-info"}><span className={"contact-name"}>{v.name}</span><div className={"contact-actions"}><span>+</span><span>-</span><span>unread {(contMap[v.id]||0)}</span></div></div></div>
                ))
            }
            {
                requests.map((r:any, i:number)=>(
                    <div key={i} className={"request"} onClick={handleClickR(r)}>
                        <div className={"request-info"}>
                            <span className={"request-body"}>{r.from===user.id?"You":r.from} Sent a request to </span>
                            <span className={"request-body"}>{r.from===user.id?r.to:"You"}</span>
                            {r.to===user.id && (
                                <div className={"request-actions"}>
                                    <div className={"AcceptChatRequest"} onClick={()=>{acceptChatRequest({id: r.from, picture: rando, name: r.from})}}>
                                        <div></div>
                                        <AddCircleIcon />
                                        <div></div>
                                    </div>
                                    <div className={"RejectChatRequest"} onClick={()=>{rejectChatRequest({id: r.from})}}>
                                        <div></div>
                                        <CancelIcon />
                                        <div></div>
                                    </div>
                                </div>
                            )}
                        
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
export default Contacts;