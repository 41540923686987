import React, { Component } from "react";
import './header.scss'
import UserAvatar from "react-user-avatar";
import Logo from '../../assets/Logo.png';
import { Button, Card, Col, Container, Row } from "reactstrap";
import UserProfile from "../user-profile/user-profile";
import { Link } from "react-router-dom";

const Header = ({user}:any)=>{
    return (
        <div>
          <div className="fillit">
          <div className="clipit">
            <div className="fillclip">
              </div>
          </div>
          <div className="whitenedheader">
            <div></div>
              <Link to="/">
                <img src={Logo} alt="Logo" className="logo"/>
              </Link>
              <div className="clear"></div>
              <div className="pfp">
              <>
                <Link to='/user-profile'>
                <UserAvatar size="52" 
                            name={user.name} 
                            src={user.picture} />
              </Link>
              </>
              </div>
              <div></div>
          </div>
          </div>
        </div>
    );
}

const AuthHeader = ({ isLoggedIn, user } :any ) => {
  if (isLoggedIn) {
    return (<Header user={user}/>);
  }
  return (<div></div>);
}

export default AuthHeader;