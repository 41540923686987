import React, { Suspense, lazy, useContext, useEffect, useState, useReducer } from 'react';
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import './App.scss';
import axios from 'axios';
import Navigation from './components/navigation/navigation';
import Header from './components/header/header';
import { Admin, Resource } from 'react-admin';
import { UserList } from './admin-dash/user-management/user-management';
import simpleRestProvider from 'ra-data-simple-rest';
import { Auth0Context, useAuth0 } from '@auth0/auth0-react'; 
import { Button } from 'reactstrap';
import Logo from './assets/Logo.png';
import rando from './assets/rando.png';
import Picture from './assets/noimage.png';
import LoginButton from './auth/loginButton';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import DirectMessage from './components/directmessage/directmessage';
import DirectMessageNotifier from './components/directmessage/directmessagenotifier';
const Splash = lazy(()=>import('./components/splash/splash'));
const Home = lazy(()=>import('./components/home/home'));
const Search = lazy(()=>import('./components/search/search'));
const Circles = lazy(()=>import('./components/circles/circles'));
const Posts = lazy(()=>import('./components/posts/posts'));
const Post = lazy(()=>import('./components/post/post'));
const CreatePost = lazy(()=>import('./components/create-post/create-post'));
const Notifications = lazy(()=>import('./components/notifications/notifications'));
const CreateEvent = lazy(()=>import('./components/create-event/create-event'));
const Settings = lazy(()=>import('./components/settings/settings'));
const UserProfile = lazy(()=>import('./components/user-profile/user-profile'));
const EventPage = lazy(()=>import('./components/event-page/event-page'));
const AdditionalImage = lazy(()=>import('./components/create-event/additional-image'));
const CuratorDashboard = lazy(()=>import('./components/curator-dashboard/curator-dashboard'));
const CuratorRequest = lazy(()=>import('./components/curator-request/curator-request'));
const EventCalendar = lazy(()=>import('./components/event-calendar/event-calendar'));
const CreateCircle = lazy(()=>import('./components/create-circle/create-circle'));
const EditEvent = lazy(() => import('./components/edit-event/edit-event'));
const LatestLogin = lazy(() => import('./components/unauthenticated/'));
const Login = lazy(() => import('./components/unauthenticated/Login'));
const Register = lazy(() => import('./components/unauthenticated/Register'));
const Verification = lazy(() => import('./components/unauthenticated/Verification'));
const FourOFour = lazy(() => import('./FourOFour'));

const randomString = (n:Number) => {
  let abc = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let s = '';
  for(let i = 0; i < n; i++){
    s+=abc[Math.floor(Math.random()*36)];
  }
  return s;
}

export const PostsContext = React.createContext({dmState: {
  viewMessages: false,
  viewMessanger: false,
  viewRequest: false,
  creatingRequest: false,
  requestId: '',
  useRequestId: false,
  contact: '',
  messages: Array(),
  contacts: Array(),
  requests: Array()
}, dispatchDM: (a:any)=>{return a}, postsState: {
  user: {id: '', name: '', picture: '', liked: Array(), reported: Array()}, 
  posting: false, 
  cAnimated: false,
  viewComments: false, 
  viewReplies: false, 
  commenting: false, 
  replying: false, 
  comments: Array(), 
  replies: Array(), 
  postId: '', 
  commentId: '', 
  replyId: '',
  posts: Array(), 
  modal: {show: false, title: '', body: '', cancel: '', accept: '', next: ()=>{}}
  },
  dispatchPosts: (a:any)=>{return a}, });

const eventsReducer = (state:any, action:any) => {
  let newDate = new Date();
  newDate.setSeconds(newDate.getSeconds()+parseInt(process.env.REACT_APP_EVENT_CACHE_TIME!));
  //console.log(`Event date: ${newDate}`);
  switch(action.type){
    case 'SET EVENTS':
      return {...state, events: action.payload, expires: newDate};
    case 'UPDATE EVENTS':
      return {...state, events: action.payload};
    default:
      return {...state};
  }
}

const circlesReducer = (state:any, action:any) => {
  let newDate = new Date();
  newDate.setSeconds(newDate.getSeconds()+parseInt(process.env.REACT_APP_CIRCLE_CACHE_TIME!));
  //console.log(`Circle date: ${newDate}`);
  switch(action.type){
    case 'SET CIRCLES DATA':
      return {...state, circlesData: action.payload, expires: newDate};
    default:
      return {...state};
  }
}
const postsReducer = (state:any, action:any) => {
  let newDate = new Date();
  newDate.setSeconds(newDate.getSeconds()+parseInt(process.env.REACT_APP_POST_CACHE_TIME!||'10'));
  switch(action.type){
    case 'SET USER':
      return {...state, user: {...state.user, ...action.payload}};
    case 'SET POSTS':
      return {...state, posts: action.payload, expires: newDate};
    case "SET POSTING":
      return {...state, posting: action.payload};
    case 'ADD POST':
      return {...state, modal: {...state.modal, show: false}, posting: false, posts: [...state.posts, {...action.payload, comments: [], reportedBy: [], likes: 0, posted: `${new Date()}`, edited: 'never', poster: {...action.user}}], expires: newDate}
    case 'ADD COMMENT':
      let p = [...state.posts];
      let com = Array();
      let idx = -1;
      for(let i = 0; i < p.length; i++){
        if(p[i].id===state.postId){
          p[i].comments = [...p[i].comments, {...action.payload, comment: action.payload.comment, replies: [], reports: 0, likes: 0, posted: new Date(), edited: 'never', poster: {...action.user}}]
          com = [...p[i].comments];
          break;
          console.log('comment added');
        }
      }
      return {...state, modal: {...state.modal, show: false}, cAnimated: true, comments: com, posts: p, commenting: false};
    case 'ADD REPLY':
      let po = [...state.posts];
      for(let i = 0; i < po.length; i++){
        if(po[i].id===action.payload.postId){
          let c = po[i].comments;
          for(let j = 0; j < po[i].comments.length; j++){
            if(c[j].id===action.payload.commentId){
              c[j].replies = [...c[j].replies, {id: c[j].id+"|"+randomString(4), content: action.payload.content, poster: action.user, likes: 0, reports: 0, posted: new Date(), edited: 'never'}];
            }
          }
          po[i].comments = [...c];
        }
      }
      return {...state, modal: {...state.modal, show: false}, posts: po, replying: false};
    case 'ADD LIKE POST':
      let pos = [...state.posts];
      for(let i = 0; i < pos.length; i++){
        if(pos[i].id===action.payload.postId){
          pos[i].likes = pos[i].likes+1;
          break;
        }
      }
      return {...state, user: {...state.user, liked: [...state.user.liked, action.payload.postId]}, posts: pos};
    case "REMOVE LIKE POST":
      let rrrpos = [...state.posts];
      for(let i = 0; i < rrrpos.length; i++){
        if(rrrpos[i].id===action.payload.postId){
          rrrpos[i].likes = rrrpos[i].likes-1;
          break;
        }
      }
      let rrplikes = [...state.user.liked];
      rrplikes.splice(rrplikes.findIndex(v=>v===action.payload.postId),1);
      return {...state, user: {...state.user, liked: rrplikes}, posts: rrrpos}
    case 'ADD REPORT POST':
      let post = [...state.posts];
      for(let i = 0; i < post.length; i++){
        if(post[i].id===action.payload.postId){
          post[i].likes = post[i].likes+1;
          break;
        }
      }
      return {...state, modal: {...state.modal, show: false}, user: {...state.user, reported: [...state.user.reported, action.payload.postId]}, posts: post}
    case 'ADD LIKE COMMENT':
      let posts = [...state.posts];
      for(let i = 0; i < posts.length; i++){
        if(posts[i].id===state.postId){
          console.log('b');
          for(let j = 0; j < posts[i].comments.length; j++){
            console.log('c');
            if(posts[i].comments[j].id===action.payload.commentId){
              console.log('d');
              posts[i].comments[j].likes+=1;
              /*let c = [...posts[i].comments];
              c[j].likes = c[j].likes+1;
              posts[i].comments = [...c];*/
              break;
            }
          }
          break;
        }
      }
      return {...state, cAnimated: true, user: {...state.user, liked: [...state.user.liked, action.payload.commentId]}, posts: posts};
    case 'REMOVE LIKE COMMENT':
      let rrrposts = [...state.posts];
      for(let i = 0; i < rrrposts.length; i++){
        if(rrrposts[i].id===state.postId){
          for(let j = 0; j < rrrposts[i].comments.length; j++){
            if(rrrposts[i].comments[j].id===action.payload.commentId){
              let c = [...rrrposts[i].comments];
              c[j].likes = c[j].likes-1;
              rrrposts[i] = {...rrrposts[i], comments: [...c]};
              break;
            }
          }
          break;
        }
      }
      let rrclikes = [...state.user.liked];
      rrclikes.splice(rrclikes.findIndex(v=>action.payload.commentId===v),1);
      return {...state, cAnimated: true, user: {...state.user, liked: rrclikes}, posts: rrrposts};
    case 'ADD REPORT COMMENT':
      let postsc = [...state.posts];
      for(let i = 0; i < postsc.length; i++){
        if(postsc[i].id===state.postId){
          for(let j = 0; j < postsc[i].comments.length; j++){
            if(postsc[i].comments[j].id===action.payload.commentId){
              let c = [...postsc[i].comments];
              c[j].reported = c[j].reported+1;
              postsc[i] = {...postsc[i], comments: [...c]};
              break;
            }
          }
          break;
        }
      }
      return {...state, modal: {...state.modal, show: false}, cAnimated: true, user: {...state.user, reported: [...state.user.reported, action.payload.commentId]}, posts: postsc}
    case 'ADD LIKE REPLY':
      let rrrpostsr = [...state.posts];
      for(let i = 0; i < rrrpostsr.length; i++){
        if(rrrpostsr[i].id===state.postId){
          for(let j = 0; j < rrrpostsr[i].comments.length; j++){
            if(rrrpostsr[i].comments[j].id===state.commentId){
              let c = [...rrrpostsr[i].comments];
              for(let k = 0; k < c[j].replies.length; k++){
                if(c[j].replies[k].id===action.payload.replyId){
                  c[j].replies[k].likes+=1;
                  rrrpostsr[i] = {...rrrpostsr[i], comments: [...c]}
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      return {...state, user: {...state.user, liked: [...state.user.liked, action.payload.replyId]}, posts: rrrpostsr}
    case 'REMOVE LIKE REPLY':
      let rrpostsr = [...state.posts];
      for(let i = 0; i < rrpostsr.length; i++){
        if(rrpostsr[i].id===state.postId){
          for(let j = 0; j < rrpostsr[i].comments.length; j++){
            if(rrpostsr[i].comments[j].id===state.commentId){
              let c = [...rrpostsr[i].comments];
              for(let k = 0; k < c[j].replies.length; k++){
                if(c[j].replies[k].id===action.payload.replyId){
                  c[j].replies[k].likes-=1;
                  rrpostsr[i] = {...rrpostsr[i], comments: [...c]}
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      let rrliked = [...state.user.liked];
      rrliked.splice(rrliked.findIndex(v=>v===action.payload.replyId),1);
      return {...state, user: {...state.user, liked: rrliked}, posts: rrpostsr}
    case 'ADD REPORT REPLY':
      let postsr = [...state.posts];
      for(let i = 0; i < postsr.length; i++){
        if(postsr[i].id===state.postId){
          for(let j = 0; j < postsr[i].comments.length; j++){
            if(postsr[i].comments[j].id===state.commentId){
              let c = [...postsr[i].comments];
              for(let k = 0; k < c[j].replies.length; k++){
                if(c[j].replies[k]===action.payload.replyId){
                  let r = [...c[j].replies];
                  r[k].reported = r[k].reported+1;
                  c[j].replies = [...r];
                  postsr[i] = {...postsr[i], comments: [...c]}
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      return {...state, modal: {...state.modal, show: false}, user: {...state.user, reported: [...state.user.reported, action.payload.replyId]}, posts: postsr}
    case 'VIEW COMMENTS':
      let cposts = [...state.posts];
      for(let i = 0; i < cposts.length; i++){
        if(cposts[i].id===action.payload.postId){
          return {...state, posting: false, postId: action.payload.postId, comments: [...cposts[i].comments], viewComments: true}
        }
      }
      return {...state};
    case 'VIEW REPLIES':
      let rposts = [...state.posts];
      for(let i = 0; i < rposts.length; i++){
        if(rposts[i].id===state.postId){
          for(let j = 0; j < rposts[i].comments.length; j++){
            if(rposts[i].comments[j].id===action.payload.commentId){;
              return {...state, cAnimated: true, commentId: action.payload.commentId, replies: [...rposts[i].comments[j].replies], viewReplies: !state.viewReplies}
            }
          }
        }
      }
      return {...state};
    case 'CLOSE COMMENTS':
      return {...state, cAnimated: false, replying: false, commenting: false, viewReplies: false, viewComments: false};
    case "SET COMMENTING":
      return {...state, cAnimated: true, replying: false, commenting: action.payload.commenting};
    case "SET REPLYING":
      return {...state, cAnimated: true, commentId: action.payload.commentId, replying: action.payload.replying};
    case "VIEW COMMENTS AND REPLY":
      let vposts = [...state.posts];
      for(let i = 0; i < vposts.length; i++){
        if(vposts[i].id===action.payload.postId){
          return {...state, postId: action.payload.postId, posting: false, comments: [...vposts[i].comments], viewComments: true, commenting: true}
        }
      }
      return {...state};
    case "SET AND VIEW MODAL":
      return {...state, cAnimated: state.viewComments, modal: {show: true, title: action.payload.title, body: action.payload.body, cancel: action.payload.cancel, accept: action.payload.accept, next: action.payload.next}}
    case "CLOSE MODAL":
      return {...state, modal: {...state.modal, show: false}};
    case "DELETE POST":
      let delposts = [...state.posts];
      const idex = delposts.findIndex((a:any)=>a.id===action.payload.postId);
      if(idex!==-1){
        delposts.splice(idex, 1);
        return {...state, modal: {...state.modal, show: false}, posts: [...delposts]};
      }
      return {...state};
    case "DELETE COMMENT":
      let delcposts = [...state.posts];
      const cidx = delcposts.findIndex((a:any)=>a.id===state.postId);
      let ccomments = [...delcposts[cidx].comments];
      const ccidx = ccomments.findIndex((a:any)=>a.id===action.payload.commentId);
      if(ccidx!==-1){
        ccomments.splice(ccidx,1);
        delcposts[cidx].comments = ccomments;
        let nComments = [...state.comments];
        nComments.splice(cidx,1);
        console.log(nComments);
        return {...state, modal: {...state.modal, show: false}, posts: delcposts, comments: nComments};
      }
      return {...state};
    case "DELETE REPLY":
      let delrposts = [...state.posts];
      const ridx = delrposts.findIndex((a:any)=>a.id===state.postId);
      let rcomments = [...delrposts[ridx].comments];
      const cridx = rcomments.findIndex((a:any)=>a.id===state.commentId);
      let rreplies = [...rcomments[cridx].replies];
      const rridx = rreplies.findIndex((a:any)=>a.id===action.payload.replyId);
      if(rridx!==-1){
        rreplies.splice(rridx,1);
        rcomments[cridx].replies = rreplies;
        delrposts[cridx].comments = rcomments;
        return {...state, modal: {...state.modal, show: false}, posts: delrposts, comments: rcomments, replies: rreplies};
      }
      return {...state};
    default:
      return {...state};
  }
}
const dmReducer = (state:any, action:any) => {
  console.log(JSON.stringify(action));
  switch(action.type){
    case "SET VIEW MESSAGES":
      return {...state, viewMessages: action.payload.viewMessages};
    case "SET VIEW MESSANGER":
      return {...state, viewMessanger: action.payload.viewMessanger};
    case "SET VIEW REQUEST":
      return {...state, viewRequest: action.payload.viewRequest};
    case "SET MESSAGES":
      return {...state, messages: action.payload.messages};
    case "SET CONTACT":
      return {...state, contact: action.payload.contact};
    case "SET REQUEST":
      return {...state, request: action.payload.request};
    case "SET CONTACTS":
      return {...state, contacts: action.payload.contacts};
    case "SET CONTACTS AND REMOVE REQUEST":
      let newReqs = [...state.requests];
      newReqs.splice(newReqs.findIndex(v=>v.id===action.payload.reqId),1);
      return {...state, contacts: action.payload.contacts, requests: newReqs}
    case "REMOVE REQUEST":
      let nnewReqs = [...state.requests];
      nnewReqs.splice(nnewReqs.findIndex(v=>v.id===action.payload.reqId),1);
      return {...state, requests: nnewReqs};
    case "SET REQUESTS":
      return {...state, requests: action.payload.requests};
    case "SET MSGS AND CONTACTS":
      return {...state, messages: action.payload.messages, contacts: action.payload.contacts};
    case "CLOSE MESSANGER":
      return {...state, viewMessages: false, viewMessanger: false, messages: action.payload.messages};
    case "OPEN MESSAGES":
      return {...state, contact: action.payload.contact, viewMessages: true};
    case "CLOSE MESSAGES":
      return {...state, messages: action.payload.messages, viewMessages: false, creatingRequest: false};
    case "MAKE CHAT REQUEST":
      const idx = state.contacts.findIndex((a:any)=>a.id===action.payload.id);
      if(idx!==-1){
        return {...state, contact: state.contacts[idx], viewMessages: true, viewMessanger: true, viewRequest: false, creatingRequest: false};
      } else {
        console.log(JSON.stringify(action.payload));
        return {...state, requestId: action.payload.id, useRequestId: true, viewMessanger: true, viewRequest: true, creatingRequest: true};
      }
      return {...state};
    case "CREATE CHAT REQUEST":
      return {...state, useRequestId: false, viewMessages: false, viewRequest: true, creatingRequest: true}
    case "CANCEL CREATE CHAT REQUEST":
      return {...state, viewRequest: false, creatingRequest: false};
    case "ADD CHAT REQUEST":
      return {...state, requests: [...state.requests, {...action.payload}], creatingRequest: false, viewRequest: false, requestId: action.payload.from===action.user.id?action.payload.to:action.payload.from};
    default:
      return {...state};
  }
}

function App() {
  const { isLoading, 
          isAuthenticated, 
          error,
          user, 
          loginWithRedirect, 
          logout, 
        } = useAuth0();
  const [connection, setConnection] = useState(new HubConnectionBuilder().withUrl('/').build());
  //const [messages, setMessages] = useState([{to: 'ajsodifjasiod', from: 'bingo', text: 'Hello', read: true}, {to: 'bingo', from: 'ajsodifjasiod', text: 'Hello, how are you?', read: true}, {to: 'ajsodifjasiod', from: 'bingo', text: 'I\'m doing good, and you?', read: true}, {to: 'bingo', from: 'ajsodifjasiod', text: 'I\'m fine thanks', read: false}]);
  //const [contacts, setContacts] = useState([{id: 'ajsodifjasiod', name: 'Barney Henderson', picture: profilePic}, {id: 'asjokdkso', name: 'Circulo Support', picture: Logo}, {id: 'kxidksow', name: 'Albert Einstein', picture: rando},{id: 'ajsodilfjasiod', name: 'Bobby Fischer', picture: profilePic}, {id: 'asjlokdkso', name: 'Mr. Circle', picture: Logo}, {id: 'kxildksow', name: 'Picaso', picture: rando}, {id: 'aosidjfaosidjf', name: 'Baker Mayfield', picture: profilePic}, {id: 'ajskdajsd', name: 'Bobby Dohnson', picture: rando}]);
  const tmpNoti = [{id: 'jasofi', type: 'CHAT REQUEST ACCEPTED', msg: 'Barney Henderson has accepted your chat request', read: true, initiated: new Date('11/15/2021'), priority: 1}, {id: 'asjoi', type: 'CIRCLE JOIN REQUEST', msg: 'Spammer has requested permission to join private circle \"The Couponers\"', link: '/circle/2', read: true, initiated: new Date('11/16/2021'), priority: 2}, {id: 'kdois', type: 'CIRCULO UPDATE', msg: 'Circulo update November 22 20UTC to 21UTC', read: false, initiated: new Date('11/17/2021'), priority: 7}];
  tmpNoti.sort((a:any,b:any)=>(new Date(b.initiated)).getTime()-(new Date(a.initiated)).getTime());
  const [notifications, setNotifications] = useState(tmpNoti);
  //const [viewMessages, setViewMessages] = useState(false);
  //const [viewMessanger, setViewMessanger] = useState(false);
  const [dmState, dispatchDM] = useReducer(dmReducer, {viewMessages: false, viewMessanger: false, contact: '', request: '', messages: Array(), contacts: [{id: 'asjodifaj', picture: rando, name: 'Billy Jones'}], requests: [{from: 'jimmy', to: (user?.id)?user.id:(user?.sub), msg: 'Hello its Jimmy from college.'}]});
  const JoinMessages = async (user:any) => {
    try {
      axios.get(`${process.env.REACT_APP_API}/messages`).then(response => {  
        //console.log(response.data);  
        dispatchDM({type: "SET MSGS AND CONTACTS", payload: {messages: response.data.messages, contacts: response.data.contacts}});
        //setMessages(response.data.messages);
        //setContacts(response.data.contacts);
      }).catch(err=>{
        //console.log(`Error: ${err}`);
      });
      axios.get(`${process.env.REACT_APP_API}/notifications`).then(response => {  
        //console.log(response.data);  
        setNotifications(response.data);
      }).catch(err=>{
        //console.log(`Error: ${err}`);
      });
      const conn = new HubConnectionBuilder().withUrl(`${process.env.REACT_APP_API}/signalr?userId=${user.sub}`).build();
      conn.on("DirectMessage", (message) => {
        dispatchDM({type: "SET MESSAGES", payload: {messages: [...dmState.messages, {...message}]}});
        //setMessages(messages => [...messages, {...message}]);
      });
      await conn.start();
      //await conn.invoke("JoinSocket", {user});
      setConnection(conn);
    } catch (e) {
      console.log(e);
    }
  }
  const readNotification = async (i:any) => {
    console.log(notifications);
    try {
      await connection.invoke("ReadNotification", {id: notifications[i].id});
    } catch (e) {
      console.log(e);
    }
    let noti = [...notifications];
    noti[i].read = true;
    setNotifications(noti);
  }
  const markRead = async (contact:any) => {
    try {
      await connection.invoke("MarkRead", {contact});
    } catch (e) {
      console.log(e);
    }
  }
  const sendMessage = async (message:any, contact:any) => {
    try {
      await connection.invoke("SendMessage", {message, contact});
    } catch (e) {
      console.log(e);
    }
  }
  const sendChatRequest = async (message: any, contact: any) => {
    try {
      await connection.invoke("SendChatRequest", {message, contact});
    } catch (e) {
      console.log(e);
    }
    dispatchDM({type: "ADD CHAT REQUEST", payload: {...message}, user: {id: message.from}});
  }
  const acceptChatRequest = async (contact: any) => {
    try {
      await connection.invoke("AcceptChatRequest", {contact});
    } catch (e) {
      console.log(e);
    }
    dispatchDM({type: "SET CONTACTS AND REMOVE REQUEST", payload: {contacts: [...dmState.contacts, contact], reqId: contact.id}});
  }
  const rejectChatRequest = async (contact: any) => {
    try {
      await connection.invoke("RejectChatRequest", {contact});
    } catch (e) {
      console.log(e);
    }
    dispatchDM({type: "REMOVE REQUEST", payload: {reqId: contact.id}});
  }
  const deleteContact = async (contact: any) => {
    try {
      await connection.invoke("DeleteContact", {contact});
      let cont = [...dmState.contacts];
      cont.splice(dmState.contacts.findIndex((v:any)=>{return v.id===contact.id}),1);
      dispatchDM({type: "SET CONTACTS", payload: {contacts: cont}});
    } catch (e) {
      console.log(e);
    }
  }
  const dismissNotification = async (notifyId: any, index: number) => {
    try {
      await connection.invoke("DismissNotification", {notifyId});
    } catch (e) {
      console.log(e);
    }
    let noti = [...notifications];
      if(noti[index].id===notifyId){
        noti.splice(index, 1);
      }
      else{
        let idx = noti.findIndex(a=>a.id===notifyId);
        if(idx!==-1){
          noti.splice(idx, 1);
        }
      }
      setNotifications(noti);
  }
  let eventDate = new Date();
  eventDate.setMinutes(59);
  let anotherDate = new Date();
  anotherDate.setHours(anotherDate.getHours()+3);
  anotherDate.setMinutes(17);
  let yetAnother = new Date(anotherDate);
  yetAnother.setHours(anotherDate.getHours()+1);
  yetAnother.setMinutes(15);
  let andyetAnother = new Date(anotherDate);
  andyetAnother.setHours(anotherDate.getHours()+1);
  andyetAnother.setMinutes(30);
  let oryetAnother = new Date(anotherDate);
  oryetAnother.setHours(anotherDate.getHours()+1);
  oryetAnother.setMinutes(45);
  let now = (new Date());
  const [eventsState, dispatchEvents] = useReducer(eventsReducer, {events: [{event_id: 'ksuejsop', 
  event_title: 'Test Title', 
  event_image: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F3.bp.blogspot.com%2F-nDfttnO5s4k%2FTmtgJvMnZhI%2FAAAAAAAAA9o%2Fn3pN4me02l0%2Fs1600%2Forange.jpg&f=1&nofb=1', 
  venue_name: 'Oranges are us', 
  date_time: eventDate, 
  event_desc: 'Farm the oranges with us.', 
  promoter_tags: ['#oranges']},{
    event_id: "ajosadi",
    event_title: "Legendary Party",
    event_image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftownsquare.media%2Fsite%2F712%2Ffiles%2F2017%2F04%2FTexas-Roadhouse.jpg%3Fw%3D1200%26h%3D0%26zc%3D1%26s%3D0%26a%3Dt%26q%3D89&f=1&nofb=1',
    venue_name: "Texas Roadhouse",
    date_time: anotherDate,
    event_desc: "Come have legendary fun with us at the roadhouse!",
    promoter_tags: ['#legendary', '#texasroadhouse'],
  },{
    event_id: "ajobsdi",
    event_title: "March Madness",
    event_image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fc.pxhere.com%2Fphotos%2Fb0%2F14%2Fbasketball_game_competition_sport_ball_teams_play_court-1088214.jpg!d&f=1&nofb=1',
    venue_name: "Basketball Stadium",
    date_time: yetAnother,
    event_desc: "Come see the amazing local basketball team!",
    promoter_tags: ['#march', '#basketball'],
  },{
    event_id: "ajocsdi",
    event_title: "Basement Band",
    event_image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.pexels.com%2Fphotos%2F2601186%2Fpexels-photo-2601186.jpeg%3Fauto%3Dcompress%26cs%3Dtinysrgb%26h%3D750%26w%3D1260&f=1&nofb=1',
    venue_name: "Charlies Basement",
    date_time: andyetAnother,
    event_desc: "Come to have fun! Great party and live band.",
    promoter_tags: ['#party', '#music'],
  },{
    event_id: "ajodsdi",
    event_title: "Hackathon",
    event_image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.rawpixel.com%2Fs3fs-private%2Frawpixel_images%2Fwebsite_content%2Fg-web-104b.jpg%3Fauto%3Dformat%26bg%3Dtransparent%26con%3D3%26cs%3Dsrgb%26dpr%3D1%26fm%3Djpg%26ixlib%3Dphp-3.1.0%26mark%3Drawpixel-watermark.png%26markalpha%3D90%26markpad%3D13%26markscale%3D10%26markx%3D25%26q%3D75%26usm%3D15%26vib%3D3%26w%3D1400%26s%3D48cfe88db6110333ef4d969d1f420abc&f=1&nofb=1',
    venue_name: "Global Remote",
    date_time: oryetAnother,
    event_desc: "Welcome to the 2022 Hackathon Cup",
    promoter_tags: ['#contest', '#programming', '#hacking'],
  },], expires: new Date("1/1/1980")});
  const [circlesState, dispatchCircles] = useReducer(circlesReducer, {circlesData: [], expires: new Date('1/1/1980')});
  const [postsState, dispatchPosts] = useReducer(postsReducer, {user: {id: user?.sub, liked: Array(), reported: Array(), ...user},
  posting: false, 
  cAnimated: false,
  viewComments: false, 
  viewReplies: false, 
  commenting: false, 
  replying: false, 
  comments: [], 
  replies: [], 
  postId: '', 
  commentId: '', 
  replyId: '',
  expires: new Date('1/1/1980'),
  posts: [{coverMedia:"",content:"<p>Hello World! This is my first post.</p>",comments:[],id:"wo5zyhxi",reportedBy:[],likes:0,posted:"Sun Nov 28 2021 14:16:36 GMT-0700 (Mountain Standard Time)",edited:"never",poster:{id:"ajosdifajsodfij",picture:Picture,name:"Yvonne Parker"}}], 
  modal: {show: false, title: '', body: '', cancel: '', accept: '', next: ()=>{}}});//useReducer(postsReducer, {posts: [{id: 'abcdefgh', coverMedia: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.istockphoto.com%2Fvectors%2Fupcoming-events-neon-signs-vector-upcoming-events-design-template-vector-id978975308%3Fk%3D6%26m%3D978975308%26s%3D170667a%26w%3D0%26h%3DpLdvNmdeKDgh1fz6rY7FuQqzN0YXKiSnzWZP6DAbMsg%3D&f=1&nofb=1', content: '<p>Here are some of our newest upcoming events that are are offering to the public. If you are interested, please note that you must be at least 16+, and have valid identification. Without such identification you will not be able to attend these events.</p><p>Popcorn plaza is hosting its first all you can eat popcorn stands with any movie night pass during the first week of September.</p><p>The Ice cream barn is serving free shaved ice during the first day of Summer (check their website for terms).</p>', comments: [{id: 'abcdefghi|jklmno', content: '<p>This is so cool can\'t wait to enjoy that delicious popcorn. Will they be featuring a fire truck this year?</p>', replies: [{id: 'abcdefghi|jklmno|pqrs', content: '<p>No unfortunetly there will be no fire truck this year unless we are all surprised by the fire department.</p>', reportedBy: [], likes: 0, posted: new Date('4/11/2021 7:9:20'), edited: 'never', poster: user}], reportedBy: [], likes: 1, posted: new Date('3/11/2021 07:07:22'), edited: 'never', poster: user}], reportedBy: [], likes: 13, posted: new Date('3/11/2021 05:17:20'), edited: 'never', poster: user}], expires: new Date("1/1/1980")});
  const socketActions = {markRead: markRead, sendMessage: sendMessage, sendChatRequest: sendChatRequest, acceptChatRequest: acceptChatRequest, rejectChatRequest: rejectChatRequest, deleteContact: deleteContact, dismissNotification: dismissNotification}
  useEffect(()=>{
    if(isAuthenticated){
      console.log(user);
      dispatchPosts({type: "SET USER", payload: user});
      JoinMessages(user);
      //console.log(user);
      let now = new Date();
      if(now.valueOf()>new Date(circlesState.expires).valueOf()){
        //console.log('run circles');
        axios.get(`${process.env.REACT_APP_API}${process.env.REACT_APP_ROUTE_GETALL_CIRCLES}`).then(response => {  
          //console.log(response.data);  
          dispatchCircles({type:'SET CIRCLES DATA', payload: response.data});
        }).catch(err=>{
          //console.log(`Error: ${err}`);
        });
      }
      if(now.valueOf()>new Date(eventsState.expires).valueOf()){
        //console.log('run events');
        axios.get(`${process.env.REACT_APP_API}${process.env.REACT_APP_ROUTE_GETALL_EVENTS}`).then(response => {  
          //console.log(response.data);  
          dispatchEvents({type:'SET EVENTS', payload: response.data});
        }).catch(err=>{
          //console.log(`Error: ${err}`);
          console.log(eventsState);
        });
      }
      console.log('fetching posts');
      console.log(now.valueOf()>new Date(postsState.expires).valueOf());
      if(now.valueOf()>new Date(postsState.expires).valueOf()){
        console.log('run posts');
        axios.get(`${process.env.REACT_APP_API}${process.env.REACT_APP_ROUTE_GETALL_POSTS}`).then(response => {  
          console.log(response.data);  
          dispatchPosts({type:'SET POSTS', payload: response.data});
        }).catch(err=>{
          console.log(`Error: ${err}`);
        });
      }
    }
    
  },[isAuthenticated]);
        if (isLoading) {
          return <div>Loading...</div>;
        }
        if (error&&false) {
          return <div>Oops... {error?.message}</div>;
        }
      
        if (isAuthenticated) {
          return (
    <div className="App">
    <BrowserRouter>
    	<div className="">
	    <Header isLoggedIn={true} user={user} />
	</div>
  <div className="conditional-spacer"></div>
  {!dmState.viewMessanger && (<DirectMessageNotifier user={user} dmState={dmState} dispatchDM={dispatchDM} />)}
  {dmState.viewMessanger && (<DirectMessage user={user} socketActions={socketActions} dmState={dmState} dispatchDM={dispatchDM} />)}
    <Navigation notifications={notifications}/>
        <Switch>
          <Route path="/splash" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Splash /></Suspense>)}} />
          <Route path="/" exact component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Home user={user} eventsState={eventsState} dispatchEvents={dispatchEvents}/></Suspense>)}} />
          <Route path="/home" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Home user={user} eventsState={eventsState} dispatchEvents={dispatchEvents}/></Suspense>)}} />
          <Route path="/search" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Search /></Suspense>)}} />
          <Route path="/circles" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Circles user={user} circlesState={circlesState} dispatchCircles={dispatchCircles}/></Suspense>)}} />
          <Route path="/posts" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><PostsContext.Provider value={{dmState: dmState, dispatchDM: dispatchDM, postsState: postsState, dispatchPosts: dispatchPosts}}><Posts /></PostsContext.Provider></Suspense>)}} />
          <Route path="/post/:post_id" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Post user={user} postsState={postsState} dispatchPosts={dispatchPosts}/></Suspense>)}} />
          <Route path="/createPost" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><CreatePost user={user} /></Suspense>)}} />
          <Route path="/notifications" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Notifications readNotification={readNotification} notifications={notifications} socketActions={socketActions}/></Suspense>)}} />
          <Route path="/create-event" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><CreateEvent /></Suspense>)}} />
          <Route path="/settings" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><Settings /></Suspense>)}} />
          <Route path="/user-profile" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><UserProfile user={user}/></Suspense>)}} />
          <Route path="/event-page/:event_id" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><EventPage defaultEvents={eventsState.events}/></Suspense>)}} />
          <Route path="/additional-image" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><AdditionalImage /></Suspense>)}} />
          <Route path="/curator-dashboard" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><CuratorDashboard eventsState={eventsState} dispatchEvents={dispatchEvents} /></Suspense>)}} />
          <Route path="/curator-request" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><CuratorRequest /></Suspense>)}} />
          <Route path="/event-calendar" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><EventCalendar eventCalendarState={eventsState} dispatchEventCalendar={dispatchEvents}/></Suspense>)}} />
          <Route path="/create-circle" component={()=>{return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><CreateCircle /></Suspense>)}} />
          <Route path="/edit-event/:event_id" component={() => { return (<Suspense fallback={<h1 className={"fallbackLoading"}>Loading...</h1>}><EditEvent /></Suspense>) }} />
          <Route path="/"><h1>404 page not found</h1></Route>
        </Switch>
      </BrowserRouter>
    </div>
          );
          } else {
            return (<BrowserRouter>
              <Switch>
                <Route path="/" exact component={() => {return (<Suspense fallback={<h1>Loading ...</h1>}><LatestLogin /></Suspense>)}} ></Route>
                <Route path="/login" exact component={() => {return (<Suspense fallback={<h1>Loading ...</h1>}><Login loginWithRedirect={loginWithRedirect}/></Suspense>)}} ></Route>
                <Route path="/register" exact component={() => {return (<Suspense fallback={<h1>Loading ...</h1>}><Register loginWithRedirect={loginWithRedirect}/></Suspense>)}} ></Route>
                <Route path="/verification" exact component={() => {return (<Suspense fallback={<h1>Loading ...</h1>}><Verification /></Suspense>)}} ></Route>
                <Route path="/" component={()=>{return (<Suspense fallback={<h1>Loading ...</h1>}><FourOFour /></Suspense>)}}></Route>
              </Switch>
            </BrowserRouter>)
          }

} 

export default App;
