import React, {useEffect, useState} from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Draggable from 'react-draggable';
import Contacts from '../contacts/contacts';
import ExamplePic from '../../assets/Logo.png';
import Messages from '../messages/messages';
import BackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import CreateRequest from '../create-request/create-request';

import "./directmessage.scss";
import { scryRenderedComponentsWithType } from 'react-dom/test-utils';

const DirectMessage = ({user,socketActions, dmState, dispatchDM}:any) => {
    const {viewMessages, viewRequest, creatingRequest, messages, contacts, contact, requests, request} = dmState;
    //const [contact, setContact] = useState({id: 'abc', name: 'xyz', picture: ExamplePic});
    const [hoverClose, setHoverClose] = useState(false);
    const {rejectChatRequest, acceptChatRequest, deleteContact, markRead, sendMessage, sendChatRequest} = socketActions;
    const tmpMsg = [...messages];
    tmpMsg.forEach((v:any,i:number)=>{
        if(v.from===contact.id&&!v.read){
            tmpMsg[i].read = true;
        }
    });
    const unreadCount = tmpMsg.reduce((a:any,b:any)=>{return a+(b.from===user.id||b.read?0:1)},0)+requests.filter((r:any)=>r.to===user.id).length;
    /*const [cliX, setCliX] = useState(50);
    const [cliY, setCliY] = useState(100);*/
    const [isMini, setIsMini] = useState(false);
    const [state, setState] = useState({diffX: 0, diffY: 0, dragging: false, styles: {top: "143px", left: "50px"}});
    const [sendMsg, setSendMsg] = useState('');
    const [sendCnt, setSendCnt] = useState(dmState.requestId);
    const handleLeave = (e:any) => {
        if(state.dragging){
            const left = e.screenX - state.diffX;
            const top = e.screenY - state.diffY;
            setState({...state, styles: {left: `${left}px`, top: `${top}px`}})
        }
    }
    const handleClose = () => {
        dispatchDM({type: "CLOSE MESSANGER", payload: {messages: [...messages]}});
    }
    const handleAdd = () => {
        dispatchDM({type: "CREATE CHAT REQUEST"});
    }
    const handleCloseAdd = () => {
        dispatchDM({type: "CANCEL CREATE CHAT REQUEST"});
        setSendMsg('');
        setSendCnt('');
    }
    const handleStart = (e:any) => {
        console.log(e);
    }
    const handleDrag = (e:any) => {
        console.log(e);
    }
    const handleStop = (e:any) => {
        console.log(e);
    }
    return (
        <Draggable axis="both" handle=".direct-dragging" defaultPosition={{x: 0, y: 0}} grid={[10,10]} scale={1} onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
            <div className={"direct-messanger"+(isMini?" minimized":"")} style={state.styles} onMouseLeave={handleLeave}>
                <div className={"direct-dragging"}>
                <CancelIcon className={"close-btn"} onClick={handleClose} onMouseOver={(e:any)=>{setHoverClose(true)}} onMouseLeave={(e:any)=>{setHoverClose(false)}} color={hoverClose?"error":"warning"}/>
                {isMini && (<MaximizeIcon onClick={()=>{setIsMini(false)}}/>)}
                {!isMini && (<MinimizeIcon onClick={()=>{setIsMini(true)}}/>)}
                {!creatingRequest && (<AddCircleIcon className={"add-btn"} onClick={handleAdd} color="primary"/>)}
                {creatingRequest && (<CancelIcon className={"add-btn"} onClick={handleCloseAdd} color="primary"/>)}
                {unreadCount > 0 && (<span>{unreadCount} unread message{unreadCount>1?'s':''}</span>)}
                </div>
                <div className={"direct-content"}>
                    {(!viewMessages && !viewRequest) && (<Contacts user={user} acceptChatRequest={acceptChatRequest} rejectChatRequest={rejectChatRequest} messages={messages} contacts={contacts} requests={requests} dispatchDM={dispatchDM}/>)}
                    {viewMessages && (<div className={"go-back"} ><BackIcon onClick={()=>{dispatchDM({type: "CLOSE MESSAGES", payload: {messages: [...messages]}});}}/><div className={"info"}><span>Conversation with {contact.name}</span></div></div>)}
                    {viewMessages && (<Messages messages={messages} contact={contact}/>)}
                    {creatingRequest && (<TextField className={"req-input"} value={sendCnt} onChange={(e)=>{let v = e.target.value; let s = ""; for(let i = 0; i < v.length; i++) if(v[i]!=" ") s+=v[i]; setSendCnt(s);}} label={"User"} variant={"outlined"} />)}
                    {(viewMessages||creatingRequest) && (<TextField className={"msg-input"} value={sendMsg} onChange={(e)=>{setSendMsg(e.target.value)}}label={"Message"} variant={"outlined"} />)}
                    {viewMessages && (<SendIcon className={"send-msg"} onClick={()=>{if(sendMsg!==''){dispatchDM({type: "SET MESSAGES", payload: {messages: [...messages, {from: user.id, to: contact.id, text: sendMsg}]}}); sendMessage({from: user.id, to: contact.id, text: sendMsg}, {...contact}); setSendMsg('');}}}/>)}
                    {creatingRequest && (<SendIcon className={"send-msg"} onClick={()=>{sendChatRequest({from: user.id, to: sendCnt, text: sendMsg},sendCnt); setSendMsg(''); setSendCnt('')}} />)}
                    {(!viewMessages && viewRequest) && (<CreateRequest dispatchDM={dispatchDM} dmState={dmState}/>)}
                </div>
            </div>
        </Draggable>
    )
}
export default DirectMessage;