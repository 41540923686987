import React, {useEffect, useState} from 'react';
import Badge from '@mui/material/Badge';
import "./directmessage.scss";

const DirectMessageNotifier = ({user, dmState, dispatchDM}:any) => {
    const {messages, requests} = dmState;
    const unreadCount = messages.reduce((a:any,b:any)=>{return a+(b.from===user.id||b.read?0:1)},0)+requests.filter((r:any)=>r.to===user.id).length;
    const [clicked, setClicked] = useState(false);
    let t = setTimeout(()=>{},0);
    useEffect(()=>{
        return ()=>{clearTimeout(t)};
    },[]);
    const handleClick = () => {
        if(clicked){
            clearTimeout(t);
            dispatchDM({type: "SET VIEW MESSANGER", payload: {viewMessanger: true}});
        } else {
            t = setTimeout(()=>{setClicked(false)},3000);
            setClicked(true);
        }
    }
    console.log('unreadcount',unreadCount);
    return (
        <div className={"dm-notifier"+(clicked?" clicked": '')} onClick={handleClick}>
            <Badge badgeContent={unreadCount} color="primary">
                <span className={"label"}>messages</span>
            </Badge>
        </div>
    )
}
export default DirectMessageNotifier;